<template>
  <div class="dataTable-footer justify-content-end">
    <p class="dataTable-footer-item dataTable-all">全{{ listLength }}件</p>
    <p class="dataTable-footer-item dataTable-display">{{ startIndex }}-{{ endIndex }}</p>
    <div class="dataTable-footer-item dataTable-nav">
      <button
        :class="
          isInFirstPage ? 'dataTable-nav-btn disabled' : 'dataTable-nav-btn'
        "
        :disabled="isInFirstPage"
        type="button"
        @click="offset = 1"
      >
        <i class="aikon aikon-chevron_first dataTable-nav-icon"></i>
      </button>
      <button
        :class="isInFirstPage ? 'dataTable-nav-btn disabled' : 'dataTable-nav-btn'"
        :disabled="isInFirstPage"
        type="button"
        @click="offset--"
      >
        <i class="aikon aikon-chevron_left dataTable-nav-icon"></i>
      </button>
      <div class="input-page">
        <input v-number :value="offset" @blur="onChangePage" v-on:keyup.enter="onChangePage" />
        / {{ pageTotal }} ページ
      </div>
      <button
        :class="isInLastPage ? 'dataTable-nav-btn disabled' : 'dataTable-nav-btn'"
        :disabled="isInLastPage"
        type="button"
        @click="offset++"
      >
        <i class="aikon aikon-chevron_right dataTable-nav-icon"></i>
      </button>
      <button
        :class="isInLastPage ? 'dataTable-nav-btn disabled' : 'dataTable-nav-btn'"
        :disabled="isInLastPage"
        type="button"
        @click="offset = pageTotal"
      >
        <i class="aikon aikon-chevron_last dataTable-nav-icon"></i>
      </button>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    listLength: {
      type: Number,
      default: 0,
    },
    modulePath: {
      type: String,
      required: true,
    },
    searchConditions: {
      type: Object,
    },
    limit: {
      type: Number,
      default: 15,
    },
    subdomain: {
      type: String,
    },
    shopId: {
      type: [String, Number],
    },
    noGetPaginationList: {
      type: Boolean,
      default: false,
    },
    noResetParamsStatePath: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      offset: 1,
      currentRouteName: '',
      listParams: {},
    };
  },
  computed: {
    pageTotal() {
      return Math.ceil(this.listLength / this.limit);
    },
    startIndex() {
      return this.pageTotal ? this.limit * (this.offset - 1) + 1 : 0;
    },
    endIndex() {
      const fullIndex = this.limit * this.offset;
      if (fullIndex > this.listLength) {
        return this.listLength;
      }
      return fullIndex;
    },
    isInFirstPage() {
      return this.offset === 1;
    },
    isInLastPage() {
      return this.offset >= this.pageTotal || this.endIndex === 0;
    },
    storeModule() {
      return this.modulePath.split('/')[0];
    },
    paramsStatePath() {
      return `${this.storeModule}/listParams`;
    },
    sortText() {
      return this.$store.state[this.storeModule]?.sortText || '';
    },
    sortType() {
      return this.$store.state[this.storeModule]?.sortType || '';
    },
  },
  async mounted() {
    const loading = this.$loading.show();
    this.currentRouteName = this.$route.name;
    const initialListParams = JSON.parse(sessionStorage.getItem(this.paramsStatePath));
    if (!this.noResetParamsStatePath) sessionStorage.removeItem(this.paramsStatePath);
    if (!this.noGetPaginationList) {
      await this.getPaginationList(initialListParams);
    }
    this.$loading.clear(loading);
  },
  beforeDestroy() {
    if (this.$route.name.includes(this.currentRouteName) && this.$route.name.includes('Edit') && this.$route.name !== 'SettingEventChildEditBase') {
      sessionStorage.setItem(this.paramsStatePath, JSON.stringify(this.listParams));
    } else {
      sessionStorage.removeItem(this.paramsStatePath);
      if (this.sortText) {
        this.$store.commit(`${this.storeModule}/SET_SORT_TEXT`, '');
        this.$store.commit(`${this.storeModule}/SET_SORT_TYPE`, '');
      }
    }
  },
  watch: {
    limit() {
      this.resetPagination();
    },
    offset() {
      this.getPaginationList();
    },
    searchConditions() {
      this.resetPagination();
    },
    sortText() {
      this.getPaginationList();
    },
    sortType() {
      this.getPaginationList();
    },
    listLength() {
      if ((this.listLength % this.limit === 0) && (this.offset > this.pageTotal)) {
        this.resetPagination();
      }
    },
    noGetPaginationList(value, oldValue) {
      if (!value && oldValue) {
        const initialListParams = JSON.parse(sessionStorage.getItem(this.paramsStatePath));
        this.getPaginationList(initialListParams);
      }
    }
  },
  methods: {
    async resetPagination() {
      this.offset = 1;
      await this.getPaginationList();
    },
    async getPaginationList(initialListParams) {
      if (!initialListParams) {
        this.listParams = {
          ...this.searchConditions,
          offset: this.offset,
          limit: this.limit,
        };
        if (this.sortText) {
          this.listParams.orderBy = this.sortText;
          this.listParams.sortOrder = this.sortType;
        }
        if (this.shopId) {
          this.listParams.shopId = this.shopId;
        }
      } else {
        this.listParams = initialListParams;
        this.offset = initialListParams.offset;
      }
      if (this.subdomain) {
        this.listParams.subdomain = this.subdomain;
      }
      await this.$store.dispatch(this.modulePath, this.listParams);
    },
    onChangePage(e) {
      if (this.offset == e.target.value) return;
      e.target.value = e.target.value <= 0 ? 1 : e.target.value;
      if (e.target.value >= ((this.listLength / this.limit) + 1)) return;
      this.offset = parseInt(e.target.value);
    }
  },
};
</script>

import { formatUploadDate } from '@/helpers/formatData';
import { StatusConstants } from '@/constants/status';

export default {
  data() {
    return {
      searchForm: {},
      payloadDownloadCsv: {},
      payloadSearchSummary: {},
    };
  },
  created() {
    this.initForm();
    this.initCondition();
    const paramsStorage = sessionStorage.getItem(`${this.$data.storeModule || this.storeModule}/listParams`);
    const initialListParams = paramsStorage ? JSON.parse(paramsStorage) : {};
    const isListParamsEmpty = !Object.keys(initialListParams).length;
    this.searchForm = {
      ...this.searchForm,
      ...initialListParams,
      ...((
        !this.searchForm?.startDate &&
        !this.searchForm?.endDate &&
        ['EventSummary', 'ShopSummary'].includes(this.$route.name)
      ) ? {
        startDate: this.searchForm?.startDate || formatUploadDate(new Date()),
        endDate: this.searchForm?.endDate || formatUploadDate(new Date())
      } : {}),
      ...((
        !this.searchForm?.type &&
        !this.searchForm?.isActiveCpm &&
        ['ShopUser', 'EventUser'].includes(this.$route.name)
      ) ? {
        type: !this.searchForm?.type && isListParamsEmpty ? StatusConstants.customerType.all.value : initialListParams?.type,
        isActiveCpm: !this.searchForm?.isActiveCpm && isListParamsEmpty ? [] : initialListParams?.isActiveCpm,
      } : {})
    };
    this.search();
  },
  methods: {
    search() {
      if (this.initialSearchConditions) {
        this.$data.searchConditions = { ...this.initialSearchConditions, ...this.searchForm };
      } else {
        this.$data.searchConditions = { ...this.searchForm };
      }
      this.payloadDownloadCsv = { ...this.searchForm };
      this.payloadSearchSummary = { ...this.searchForm };
    },
    resetSearch() {
      this.initForm();
      if (Object.keys(this.$data.searchConditions > 0)) {
        this.initCondition();
      }
      if(this.$refs.searchSelect) this.$refs.searchSelect.resetSelection();
      this.payloadDownloadCsv = { ...this.searchForm };
      this.payloadSearchSummary = { ...this.searchForm };
    },
    initForm() {
      this.searchFields.forEach((fieldName) => {
        this.searchForm[fieldName] = this.initialSearchConditions && this.initialSearchConditions[fieldName] ? this.initialSearchConditions[fieldName] : "";
      });
    },
    initCondition() {
      if (this.initialSearchConditions) {
        this.$data.searchConditions = { ...this.initialSearchConditions };
      } else {
        this.$data.searchConditions = {};
      }
    },
  },
};

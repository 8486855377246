import { formatLocalString, endline } from '@/helpers/formatData';
import { CommonConstants } from '@/constants/common';
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      useTicketFlagNumber: 'event/useTicketFlagNumber',
      useTicketFlag: 'event/useTicketFlag',
    }),
    modulePath() {
      return this.useTicketFlag ? this.ticketModulePath : this.normalModulePath;
    },
    downloadCSVPath() {
      return this.useTicketFlag ? this.ticketCSVPath : this.normalCSVPath;
    },
    noGetPaginationList() {
      return this.useTicketFlagNumber === undefined;
    }
  },
  methods: {
    movePositionOfAmount(labelArr) {
      const amountIndex = labelArr.findIndex(item => item.key === 'amount');
      const amountItem = labelArr.splice(amountIndex, 1);
      labelArr.splice(amountIndex + 1, 0, ...amountItem);
    },
    customExtractAmountFromArray(data, isCharge = false, key) {
      const newData = isCharge ? data[key] : data;
      return (newData || []).map((item, index) => {
        return {
          value: '¥' + formatLocalString(isCharge ? item : (item.quantity * item.amountBreakdown)),
          class: `${(isCharge ? data.ticketName[index]?.length : item.name?.length) > 10 ? 'lh-40' : '' } ${index !== newData?.length -1 ? 'mb-2 ' : ''}`
        };
      }) || [];
    },
    customExtractTicketNameFromArray(data, isCharge = false) {
      return data?.map((item, index) => {
        if(!isCharge && !item.name) return '';
        return {
          value: endline(isCharge ? item : item.name, 10),
          class: `${index !== data.length -1 ? 'mb-2 ' : ''}`,
        };
      }) || [];
    },
    async getTicketList(params) {
      this.useTicketFlag && await this.$store.dispatch('history/getTicketList', params);
    },
    handleBlur(e, key) {
      this.searchForm[key] = e.target.value.replaceAll(' ', '');
      if (!CommonConstants.NUMBER_CHARACTER_AND_HYPHEN_VALIDATION_REGEX.test(this.searchForm[key])) {
        e.target.value = '';
        this.searchForm[key] = '';
      }
    },
  },
};

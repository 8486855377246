import { StatusConstants } from '@/constants/status';

export default {
  computed: {
    historyStatus() {
      switch(this.historyDetail.type) {
        case StatusConstants.history.payment.manual.value:
          return this.historyDetail.shop ? StatusConstants.payment.manual.shop : StatusConstants.payment.manual.office;
        case StatusConstants.history.payment.own.value:
        case StatusConstants.history.payment.shop.value:
          return StatusConstants.payment.own[this.historyDetail.status];
        default:
          return StatusConstants.history.payment[this.historyDetail.type]?.label;
      }
    },
    hasShopRole() {
      return this.$store.getters['auth/hasShopRole']
    },
    paymentHistoryStatus() {
      const item = this.historyDetail;
      const shopName = item.shop?.name;
      const clientName = item.client?.name;
      switch(item.type) {
        case StatusConstants.history.payment.manual.value:
          if (!item.status) return 'キャンセル';
          return '事務局消費';
        case StatusConstants.history.payment.shop.value:
          if (!item.status) return 'キャンセル';
          return '店舗消費';
        case StatusConstants.history.payment.own.value:
          if (item.status) return '支払い済み';
          return 'キャンセル';
        case StatusConstants.history.payment.cancel.value:
          return '取り消し';
        case StatusConstants.history.payment.issuerCancel.value:
          return `取り消し（${clientName}）`;
        case StatusConstants.history.payment.expired.value:
          return shopName || clientName ? `有効期限切れ（${clientName || shopName}）` : '有効期限切れ';
        default:
          return '';
      }
    },
    isCancelDisabled() {
      const specialTypes = [StatusConstants.history.payment.expired.value, StatusConstants.history.payment.cancel.value, StatusConstants.history.payment.issuerCancel.value];
      return specialTypes.includes(this.historyDetail.type) || !this.historyDetail.status || !this.historyDetail.isCancel;
    },
  },
  watch: {
    confirmModal(val) {
      if (!val) {
        this.resetHistoryDetail();
      }
    },
  },
  methods: {
    getShopName(item) {
      const shopName = item.shop?.name;
      switch(item.type) {
        case StatusConstants.history.payment.shop.value:
        case StatusConstants.history.payment.own.value:
          if (item.status) return shopName;
          return `キャンセル（${shopName}）`;
        case StatusConstants.history.payment.expired.value:
          return StatusConstants.history.payment.expired.label;
        default:
          return '';
      }
    },
    getPaymentShopName(item) {
      const shopName = item.shop?.name;
      const clientName = item.client?.name;
      switch(item.type) {
        case StatusConstants.history.payment.manual.value:
          if (!item.status) return 'キャンセル（事務局）';
          return '事務局';
        case StatusConstants.history.payment.shop.value:
          if (!item.status) return shopName ? `キャンセル（${shopName}）` : 'キャンセル';
          return shopName;
        case StatusConstants.history.payment.own.value:
          if (item.status) return shopName;
          return this.hasShopRole ? `キャンセル（${shopName}）` : `キャンセル`;
        case StatusConstants.history.payment.cancel.value:
          return '取り消し';
        case StatusConstants.history.payment.issuerCancel.value:
          return `取り消し（${clientName}）`;
        case StatusConstants.history.payment.expired.value:
          return shopName || clientName ? `有効期限切れ（${clientName || shopName}）` : '有効期限切れ';
        default:
          return '';
      }
    },
    setSelectedShopId(shop) {
      this.searchForm.shopId = shop.map((el) => el.id);
    },
    resetHistoryDetail() {
      this.$store.dispatch('history/resetHistoryPaymentDetail');
    },
    showCancelPaymentPopup() {
      if(!this.isCancelDisabled) this.showModal('cancelPaymentModal')
    },
    async cancelPayment() {
      if (!this.isCancelDisabled) {
        const result = await this.$store.dispatch('history/cancelHistoryPayment', {
          subdomain: this.subdomain,
          id: this.item.id,
        });
        if (result) {
          this.$store.dispatch('common/setButtonLoading', true);
          await this.$store.dispatch('history/getHistoryPaymentDetail', {
            id: this.item.id,
            subdomain: this.subdomain,
          });
          this.closeModal('cancelPaymentModal');
          this.$refs.pagination.getPaginationList();
          this.$message.showSuccess('cancelPayment');
        }
      }
    },
  }
}

<template>
  <router-link class="numDisplay" :to="url" v-if="link" :class="[setPosition, setColor]">
    <p class="numDisplay-title" v-if="this.$slots.title">
      <slot name="title"></slot>
    </p>
    <div class="numDisplay-body">
      <p class="numDisplay-main" v-if="this.$slots.num">
        <slot name="num"></slot><span class="numDisplay-main-sup" v-if="this.$slots.sup"><slot name="sup"></slot></span>
      </p>
      <p class="numDisplay-sub" v-if="this.$slots.sub"><slot name="sub"></slot></p>
    </div>
    <div class="numDisplay-btn"><i class="aikon aikon-arrow_right numDisplay-btn-icon"></i></div>
  </router-link>
  <div class="numDisplay" v-else-if="!link" :class="[setPosition, setColor]">
    <p class="numDisplay-title" v-if="this.$slots.title">
      <slot name="title"></slot>
      <span class="numDisplay-subTitle"><slot name="subTitle"></slot></span>
    </p>
    <div class="numDisplay-body">
      <p class="numDisplay-main" v-if="this.$slots.num">
        <slot name="num"></slot><span class="numDisplay-main-sup" v-if="this.$slots.sup"><slot name="sup"></slot></span>
      </p>
      <p class="numDisplay-sub" v-if="this.$slots.sub"><slot name="sub"></slot></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NumDisplay',
  props: {
    url: String,
    link: {
      default: false,
      type: Boolean,
    },
    position: String,
    color: String,
  },
  computed: {
    setPosition: function() {
      if (this.color != undefined) {
        switch (this.position) {
          case 'center':
            return 'numDisplay-center';
          case 'start':
            return 'numDisplay-start';
          case 'end':
            return 'numDisplay-end';
        }
      }
      return false;
    },
    setColor: function() {
      if (this.color != undefined) {
        switch (this.color) {
          case 'dark':
            return 'numDisplay-dark';
        }
      }
      return false;
    },
  },
};
</script>

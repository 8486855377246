export default {
  data: function() {
    return {
      sort: {
        key: '',
        isAsc: false,
        isDesc: false,
      },
      sortText: '',
      sortType: '',
    };
  },
  created () {
    const paramsStorage = sessionStorage.getItem(`${this.storeModule}/listParams`);
    const params = paramsStorage ? JSON.parse(paramsStorage) : {};
    this.sortText = params.orderBy;
    this.sortType = params.sortOrder;
    
  },
  methods: {
    sortBy(key, order) {
      this.sortType = order;
      this.sortText = key;
      this.$store.commit(`${this.storeModule}/SET_SORT_TEXT`, this.sortText);
      this.$store.commit(`${this.storeModule}/SET_SORT_TYPE`, this.sortType);
    },
  },
};
